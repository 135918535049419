/**
 *
 * @author Trung H <trung@vertics.co>
 *
 * @copyright Vertics Oy 2020
 */
import React from 'react'
// import { ROUTER_PATH } from '../../../constants'
// import SideNav from '../SideNav'
import { history } from '../../../reducers'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const AuthorizedLayout = ({ children }) => {
	// const notShowingNavRoutes = [ROUTER_PATH.REPORTS]
	const location = useLocation()
	const currentPath = location.pathname
	const page = history.location.pathname.slice(1)
	const isProjectCreationWizard = currentPath.includes('project-wizard')
	// const showingNav =
	// 	notShowingNavRoutes.indexOf(history.location.pathname) === -1
	const { isSidenavOpen } = useSelector(state => state.user)
	return (
		<div className='authorized-layout'>
			{/* {showingNav ? <SideNav /> : null} */}
			<main
				className={`authorized-layout ${
					isProjectCreationWizard ? '' : isSidenavOpen ? 'open' : 'closed'
				} ${page}`}
				style={{ overflowY: 'auto' }}
			>
				{children}
			</main>
		</div>
	)
}
export default AuthorizedLayout
